import FormGroupInput from "./Inputs/formGroupInput.vue";
import FormGroupCheck from "./Inputs/formGroupCheck.vue";

import DropDown from "./Dropdown.vue";
import PaperTable from "./PaperTable.vue";
import Button from "./Button";

import UserDetail from "./User/UserDetail.vue";
import ArticleDetail from "./Articles/ArticleDetail.vue";
import Card from "./Cards/Card.vue";
import ChartCard from "./Cards/ChartCard.vue";
import StatsCard from "./Cards/StatsCard.vue";

import SidebarPlugin from "./SidebarPlugin/index";

let components = {
  FormGroupInput,
  FormGroupCheck,
  Card,
  ChartCard,
  StatsCard,
  PaperTable,
  DropDown,
  SidebarPlugin,
  UserDetail,
  ArticleDetail
};

export default components;

export {
  FormGroupInput,
  FormGroupCheck,
  Card,
  ChartCard,
  StatsCard,
  PaperTable,
  DropDown,
  Button,
  SidebarPlugin,
  UserDetail,
  ArticleDetail
};
